
import Profile1 from "../assets/img/team/profile-picture-1.jpg"
import Profile2 from "../assets/img/team/profile-picture-2.jpg"
import Profile3 from "../assets/img/team/profile-picture-3.jpg"

const Notifications = [
    {
        "id": 1,
        "read": false,
        "image": Profile1,
        "sender": "Disclosure expiring soon!",
        "time": "1 hr ago",
        "link": "#",
        "message": `Development of ApoE SNP Selective Oligonucleotides`
    },
    {
        "id": 2,
        "read": false,
        "image": Profile2,
        "sender": "Disclosure expiring soon!",
        "time": "2 hrs ago",
        "link": "#",
        "message": `Improved Prime Editor Systems for in vivo Genome Editing`
    },
    {
        "id": 3,
        "read": false,
        "image": Profile3,
        "sender": "Disclosure expiring soon!",
        "time": "2 weeks ago",
        "link": "#",
        "message": `siRNA targeting interferon-gamma signaling for the treatment of vitiligo`
    }
]

export default Notifications;

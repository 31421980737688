
import React, { useEffect, useState, useMemo }  from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faCog, faSort, faPhone, faEnvelope, faUser, faSearch, faPlus, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Container, Dropdown, ButtonGroup, Form, InputGroup, Card, Table, Nav, Badge, Tooltip, OverlayTrigger} from '@themesberg/react-bootstrap';
import { Modal } from '@themesberg/react-bootstrap';
import { InventorAPI } from '../utils/ApiUtil';
import Pagination from "../../../components/Pagination";
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import Config from "../../../Config";
import 'react-vertical-timeline-component/style.min.css';
import disclosureTimelineMobile from "../../../assets/img/disclosure_timeline_mobile.svg";
import disclosureTimeline from "../../../assets/img/disclosure_timeline.svg";
import PropTypes from 'prop-types';

const Moment = require('moment')

const InventorTable = (props) => {

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [showDefault, setShowDefault] = useState(false);
  const [showLegend2, setShowLegend2] = useState(false);
  const handleCloseLegend2 = () => setShowLegend2(false);
  const [showLegend2Mobile, setShowLegend2Mobile] = useState(false);
  const handleCloseLegend2Mobile = () => setShowLegend2Mobile(false);
  const [currentIP, setCurrentIP] = useState([]);
  const [currentIPInventors, setCurrentIPInventors] = useState([]);
  const handleClose = () => setShowDefault(false);
  const [inventions, setInventions] = useState([]);
  const [filteredInventions, setFilteredInventions] = useState(inventions);
  const [sortDesc, setSortDesc] = useState(true);
  const [inventorEmailAddresses, setInventorEmailAddresses] = useState([]);
  const [showPartialResultsMessage, setShowPartialResultsMessage] = useState(false);
  const [userEmail] = useState(props.userEmail);

  const showImpersonate = () => {
    var currentHost = window.location.hostname || 'localhost';
    var isProd = (window.location.href.indexOf('bridge.umassmedcloud') !== -1)
    return Config.getConfig(currentHost).impersonateEnabled && !isProd;
    // return false;
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return filteredInventions.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredInventions, pageSize, sortDesc]);

  const sortCurrentIP = (ip) => {
    // Sort by ipId only if the Filing Date is the same, this enforces a consistent sort.  Otherwise sort by the date.
    let sortedIP = ip.sort((a, b) => ( a["FilingDate"] === b["FilingDate"] ? (parseInt(a['ipId']) > parseInt(b['ipId']) ? 1 : -1) : (new Moment(a["FilingDate"]).format('YYYYMMDD') < new Moment(b["FilingDate"]).format('YYYYMMDD') ? 1 : -1))  );
    setCurrentIP(sortedIP);
  }

  // Get Inventions API Call. Sets state of inventions to response.
  const getInventions = (email) => {
    // let email = Cache.getItem("email");
    let API = new InventorAPI();
    return API
    .get("inventionsbyinventor/" + email)
    .then((response) => {

      if (response.data.error_type === 'over_100_inventions') {
        setShowPartialResultsMessage(true);
      }

      // Only process data if we have some.
      if (response.data.error_type !== 'noresults') {

        // Defualt sort = Sort inventions by start date most recent.
        const sorted = response.data.response.sort((a, b) => ( new Moment(a["StartDate"]).format('YYYYMMDD') < new Moment(b["StartDate"]).format('YYYYMMDD')) ? 1 : -1);
        // Create a "deep copy" of the object so the original IP does not get modified when we loop through and create our temp ipstatus object.
        const sortedCopy = JSON.parse(JSON.stringify(sorted));

        //Create temp IPStatus object to get status and country ready for display in IP column.
        //Create only 2 status'.. Issued and Pending. Combine countries when 'status' is the same.
        for (let i=0; i<sorted.length; i++) {
          sorted[i].IPStatus = [
            {"Status":"Issued","Country":[]},
            {"Status":"Pending","Country":[]}
        ];

          if (sortedCopy[i].IP) {
              var scip = sortedCopy[i].IP;
              for (let j=0; j<scip.length; j++) {
                // prevent dupes in Issued countries array.
                if (scip[j].Status === "Issued" && !sorted[i].IPStatus[0].Country.includes(scip[j].Country)) {
                    sorted[i].IPStatus[0].Country.push(scip[j].Country)
                } else {
                  // prevent dupes in Pending countries array, also ignore ones with status of 'abandoned'.
                  if (!sorted[i].IPStatus[1].Country.includes(scip[j].Country) && !scip[j].Status.includes("Abandoned")) {
                    sorted[i].IPStatus[1].Country.push(scip[j].Country)
                  }
                }
              }
            }
        }

        // If there are any countries that have IP status of Issued, only keep the 'Issued' object in the array.
        // Otherwise, only keep the 'Pending' object in the array.
        for (let k = 0; k < sorted.length; k++) {
            if (sorted[k].IPStatus[0].Country.length > 0) {
              sorted[k].IPStatus.pop();
              // if there are more than 3 countries in the 'Issued' status array, trim it.
              if (sorted[k].IPStatus[0].Country.length > 3) {
                sorted[k].IPStatus[0].Country.splice(3,sorted[k].IPStatus[0].Country.length);
              }
            } else {
              // The 'Pending' object which was formerly the second position (1) in the status array, becomes first first (0).
              sorted[k].IPStatus.shift();
              // if there are more than 3 countries in the 'Pending' status array, trim it.
              if (sorted[k].IPStatus[0].Country.length > 3) {
                sorted[k].IPStatus[0].Country.splice(3,sorted[k].IPStatus[0].Country.length);
              }
            }
        }

        //Set the inventions, pagination page, and loading state.
        setInventions(sorted);
        setFilteredInventions(sorted);
        setCurrentPage(1);
        if (sorted.length < pageSize) {
          setPageSize(sorted.length);
        } else if (pageSize < 10) {
          setPageSize(sorted.length < 10 ? sorted.length : 10);
        }
      }

      setLoading(false);

    }).catch((error) => {
      setLoading(false);
      console.log(`exception: ${JSON.stringify(error)}`)
      return error;
      });
  };

  /* useEffect hook - similar to componentDidMount but not exactly. Empty array required as second argument
    if you want function to only fire on load and avoidendless loop */
  useEffect(() => {
    // Cache.setItem("email", email);
    getInventions(userEmail);
    // Only get the inventor emails if we are not in prod, otherwise we won't have
    // impersonation.
    if (showImpersonate()) {
      getInventorEmails();
    }
  }, []);

  //Search
  const handleSearch = (event) => {
    //Get the value entered; if empty return set back to original invention list
    let value = event.target.value.toLowerCase();
    if (!value) {
      setPageSize(10);
      setCurrentPage(1);
      setFilteredInventions(inventions);
    }

    //Search on
    let result = [];
    result = inventions.filter( (data) => {
      return (data["TrackCode"].toLowerCase().search(value) !== -1 ||
              data["Title"].toLowerCase().search(value) !== -1 ||
              data["Status"].toLowerCase().search(value) !== -1 ||
              data["Inventors"].some(i => i["FullName"].toLowerCase().search(value) !== -1));
    });
    if (result.length < pageSize) {
      setPageSize(result.length);
    } else if (pageSize < 10) {
      setPageSize(result.length < 10 ? result.length : 10);
    }
    setCurrentPage(1);
    setFilteredInventions(result);
  }

  const manualSort = (column, desc) => {
    setSortDesc(desc);
    var updatedSort;
    if (column === "statusChangeDate") {
      if (desc === true) {
        updatedSort = filteredInventions.sort((a, b) => ( new Moment(a["StatusChangeDate"]).format('YYYYMMDD') < new Moment(b["StatusChangeDate"]).format('YYYYMMDD')) ? 1 : -1);
      } else {
        updatedSort = filteredInventions.sort((a, b) => ( new Moment(a["StatusChangeDate"]).format('YYYYMMDD') > new Moment(b["StatusChangeDate"]).format('YYYYMMDD')) ? 1 : -1);
      }
    } else if (column === "startDate") {
      if (desc === true) {
        updatedSort = filteredInventions.sort((a, b) => ( new Moment(a["StartDate"]).format('YYYYMMDD') < new Moment(b["StartDate"]).format('YYYYMMDD')) ? 1 : -1);
      } else {
        updatedSort = filteredInventions.sort((a, b) => ( new Moment(a["StartDate"]).format('YYYYMMDD') > new Moment(b["StartDate"]).format('YYYYMMDD')) ? 1 : -1);
      }
    }
    setFilteredInventions(updatedSort);
  }

  //Inventor Impersonation
  const getInventorEmails = () => {
    let API = new InventorAPI();
    return API
    .get("inventors")
    .then((response) => {
      let emails = response.data.Items.map(function(item) {
        return { value: item["PrimaryEmail"], label: item["PrimaryEmail"]}
      });
      setInventorEmailAddresses(emails);
      setLoading(false);
    }).catch((error) => {
      return error;
    });
  }

  const handleImpersonation = (item) => {
    // Cache.setItem("email", item.value.toLowerCase());
    // setEmail(item.value.toLowerCase());
    setLoading(true);
    getInventions(item.value.toLowerCase());
  }

  //Table Row Component
  const TableRow = (props) => {

    // Columns/Invention Items
    const id = props["id"];
    const trackCode = props["TrackCode"];
    const title = props["Title"];
    let truncTitle = title.substring(0, 70) + "...";
    const statusChangeDate = new Moment(props["StatusChangeDate"]).format('MM/DD/YYYY');
    const status = props["Status"].trim();
    let theIPStatus = props["IPStatus"];
    const theIP = props["IP"];
    const theIPInventors = props["IPInventors"];
    const startDate = new Moment(props["StartDate"]).format('MM/DD/YYYY');
    const contactName = props["FirstLicOfficer"]?.["LicensingOfficer"];
    const contactEmail = props["FirstLicOfficer"]?.["PrimaryEmail"];
    const complete = ["Non-exclusive license", "Exclusive license", "Exclusive option", "Patent Issued", "Patent App. Filed", "Co-exclusive license", "Copyright License", "Copyright", "Not Patentable"]
    const abandoned = ["Abandoned","License Terminated", "Not Accepted", "Option Terminated", "No Patent Filed", "No Patent App. Filed", "Ceded to inventor"]

      // Status Bar - Progress percent and Color
      const statusVariant = (complete.indexOf(status) !== -1) ? "success"
        : (abandoned.indexOf(status) !== -1) ? "danger"
          : "warning";
    return (
      <tr>
        {/* TRACK CODE */}
        <td>
          <span className="fw-normal">
            {trackCode}
          </span>
        </td>

        {/* TITLE */}
        <td>
          <span className="fw-normal">
          {title !== "" && <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip>{title}</Tooltip>
            }>
            {title.length > 69 ? <span>{truncTitle}</span> : <span>{title}</span>}
          </OverlayTrigger>}
          </span>
        </td>

        {/* START DATE */}
        <td>
          <span className={`fw-normal`}> {startDate}
          </span>
        </td>

        {/* STATUS CHANGE DATE */}
        <td>
          <span className={`fw-normal`}> {statusChangeDate}
          </span>
        </td>

        {/* DISCLOSURE STATUS */}
        <td>
          <span className={`fw-normal`}>
            <div key={`bar-element-${id}`} className="d-flex align-items-center text-end mb-2">
              <span className={`shape-xs rounded-circle bg-${statusVariant} me-2`}/> {status}&nbsp;
               <StatusInfo {...props}/>
            </div>

          </span>
        </td>

        {/* IP */}
        <td className="ipCol">
          <span>
          {Array.isArray(theIPStatus) && <div>
            {theIPStatus[0].Country.length > 0 &&
              <OverlayTrigger
                trigger={['hover', 'focus']}
                overlay={
                  <Tooltip>Click for more</Tooltip>
                }>
                <Button variant={theIPStatus[0].Status === "Issued" ? "success" : "info"} size="sm" onClick={() => {setShowDefault(true); sortCurrentIP(theIP); setCurrentIPInventors(theIPInventors)}}>{theIPStatus[0].Status}
                {theIPStatus[0].Country[0] != null && <span>: {theIPStatus[0].Country.map((c, index) =><span key={`ip-status-country-${c}-${index}`}> {c === "Patent Cooperation Treaty (\"PCT\")" ? "PCT" : c}{index < theIPStatus[0].Country.length - 1 ? ", " : ""}</span>)}</span>}
                </Button>
              </OverlayTrigger>}
            </div>
          }
          </span>
        </td>

        {/* ELLIPSIS ACTIONS */}
        <td>
          {contactEmail && <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0 shadow-none">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEnvelope} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {contactName && <Dropdown.Item onClick={()=> contactEmail ? window.location=`mailto:${contactEmail}` : alert('Email not available for '+contactName)}>
                <FontAwesomeIcon icon={faEnvelope} className="me-2" /> {contactName}
              </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>}
        </td>
      </tr>
    );
  };

  //IP Item Component
  const IPItem = (props) => {
    const country = props["Country"];
    const status = props["Status"].trim();
    const date = Moment(props["FilingDate"]).format("MM/DD/YYYY");
    const appNumber = props["AppProvNumber"];
    const patentNumber = props["IPNumber"]
    const type = props["Type"];
    const complete = ["Issued"]
    const abandoned = ["Abandoned during prosecution"]

    // Badge Color
    const statusVariant = (complete.indexOf(status) !== -1) ? "success"
        : (abandoned.indexOf(status) !== -1) ? "danger"
          : "info";

    return (
      <div>
      {Moment(date).isAfter(Moment('01/01/2001')) && <div className="card mb-3">
          <div className="card-header bg-light"><b>Application Number:</b> {appNumber}</div>
          <div className="card-body">

            <div className="d-flex w-100 justify-content-between">
            <p className="mb-1"><FontAwesomeIcon icon={faGlobeAmericas} />&nbsp;<b>
            {country === "Patent Cooperation Treaty (\"PCT\")" ? "Patent Cooperation Treaty" : country}
            </b></p>
            {status==="Issued" && <span><small><b>Patent Number: </b>{patentNumber}</small></span>}
            </div>
            <div className="d-flex w-100 justify-content-between">
            <p className="mb-1"><b>Filing Date:</b> {date}</p>
            <span><Badge bg={statusVariant}>{status}</Badge></span>
            </div>
            <p className="mb-1"><b>Type:</b> {type}</p>
          </div>
          </div>
        }
        </div>
    );
  };

  //Status Info Component
  const StatusInfo = (props) => {
    let status = props["Status"].trim();
    let tooltip = "";
    let estTimeText = "Estimated time to next status change is "
    if (status === "Not Patentable") {
      tooltip = "Non-Patentable Biological Material";
    }
    else if (status === "Received") {
      tooltip = estTimeText + "1 month.";
    }
    else if (status === "Review Completed") {
        tooltip = estTimeText + "2 weeks.";
    }
    else if (status === "Sent to law firm") {
        tooltip = estTimeText + "3 months.";
    }
    else if (status === "Draft Received") {
        tooltip = estTimeText + "2 months.";
    } else if (status === "Not Accepted") {
      tooltip = (
        <span>
        <h6 className="list-group-item-heading text-light"><u>Potential Reasons</u></h6>
        <ul className="list-group">
        <li><small>Needs more data</small></li>
        <li><small>Low commercial value</small></li>
        <li><small>Not novel</small></li>
        <li><small>Not useful</small></li>
        <li><small>Obvious</small></li>
        </ul>
        </span>
      );
    }
    return (
      <span>
      {tooltip !== "" && <OverlayTrigger
        trigger={['hover', 'focus']}
        overlay={
          <Tooltip>{tooltip}</Tooltip>
        }>
        <FontAwesomeIcon icon={faInfoCircle} className="icon-dark" />
      </OverlayTrigger>}
      </span>
    );
  };

  //Mobile List Component
  const MobileList = (props) => {

    // Columns/Invention Items
    const trackCode = props["TrackCode"];
    const title = props["Title"];
    let truncTitle = title.substring(0, 40) + "...";
    const statusChangeDate = new Moment(props["StatusChangeDate"]).format('MM/DD/YYYY');
    const status = props["Status"].trim();
    const contactName = props["FirstLicOfficer"]?.["LicensingOfficer"];
    const contactEmail = props["FirstLicOfficer"]?.["PrimaryEmail"];
    const contactPhone = props["FirstLicOfficer"]?.["Phone"];
    const complete = ["Non-exclusive license", "Exclusive license", "Exclusive option", "Patent Issued", "Patent App. Filed", "Co-exclusive license", "Copyright License", "Copyright", "Not Patentable"]
    const abandoned = ["Abandoned","License Terminated", "Not Accepted", "Option Terminated", "No Patent Filed", "No Patent App. Filed", "Ceded to inventor"]

      // Status color code
      const statusVariant = (complete.indexOf(status) !== -1) ? "success"
        : (abandoned.indexOf(status) !== -1) ? "danger"
          : "warning";

    return (
      <div className="list-group-item list-group-item-action flex-column align-items-start m-1">
        <div className="d-flex w-100 justify-content-between">
        <h6 className="mb-1 fw-normal">
        {title !== "" && <OverlayTrigger
          trigger={['hover', 'focus']}
          overlay={
            <Tooltip>{title}</Tooltip>
          }>
          {title.length > 39 ? <span>{truncTitle}</span> : <span>{title}</span>}
        </OverlayTrigger>}
        </h6>
          <span className="ml-5"><Badge bg={statusVariant}>{status}</Badge></span>
        </div>
        <p className="mb-1">Status Changed: {statusChangeDate}</p>
        <div className="d-flex w-100 justify-content-between">
        <small>{trackCode}</small>

        {contactPhone || contactEmail && <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle split variant="outline-secondary" className="mobileBridgeContact">
          <span><FontAwesomeIcon icon={faUser} className="me-2" />
          {contactName && <span>{contactName}</span>}
          {!contactName && <span>N/A</span>}
          </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {contactEmail && <Dropdown.Item className="fw-bold" onClick={()=> contactEmail ? window.location=`mailto:${contactEmail}` : undefined}>
              <FontAwesomeIcon icon={faEnvelope} className="me-2" /> {contactEmail ? contactEmail : "Not available"}
            </Dropdown.Item>
            }
            {contactPhone && <Dropdown.Item className="fw-bold" onClick={()=> contactPhone ? window.location=`tel:${contactPhone}` : undefined}>
              <FontAwesomeIcon icon={faPhone} className="me-2" /> {contactPhone ? contactPhone : "Not available"}
            </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>}

        </div>
      </div>
    );
  };

  return (

    <div>

    {/*TITLE, SEARCH, NEW INVENTION BUTTON*/}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <h4>INVENTIONS</h4>
        <Button variant="primary" size="sm" className="d-none d-md-block" onClick={()=> window.open("https://umassmed.wellspringsoftware.net/kms/onlinesubmissions/submissionform/?survey_id=5", "_blank")}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />Submit a New Invention Disclosure
          </Button>
    </div>
    {  (!showPartialResultsMessage) ? null :
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 alert alert-warning">
          <small>Note:  You are associated to more than 100 inventions.  Only the first 100 results are shown below.</small>
      </div>
    }
    <div className="table-settings mb-4">
<Row>
<Col xs={2} md={5} lg={7} xl={7} style={{width:'100%'}} className="d-none d-md-block">
<div style={{cursor:'pointer',marginBottom:'10px'}}>
<figure>
<img className="legendImgThumb mx-auto" onClick={() => {setShowLegend2(true)}} src={disclosureTimeline} alt="Disclosure Status Timeline Legend"/>
<figcaption className="figure-caption">
  Disclosure Status Timeline
</figcaption>
</figure>
</div>
</Col>
</Row>
      <Row className="justify-content-between align-items-center">
        <Col xs={12} md={6} lg={3} xl={4}>
        { (!showImpersonate()) ? null :
     <Select
       options={inventorEmailAddresses}
       onChange={(value) => handleImpersonation(value)}
       placeholder="Select inventor to impersonate..."
     />
 }<p></p>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control type="text" placeholder="Search" onChange={ (event) => handleSearch(event)} />
          </InputGroup>
        </Col>
        <Col xs={1} md={1}  lg={1} xl={1} className="ps-md-0 text-end d-none d-md-block">
          <Dropdown as={ButtonGroup} >
            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0 shadow-none">
              <span className="icon icon-sm icon-gray" style={{position:'relative',top:'25px'}}>
                <FontAwesomeIcon icon={faCog}/>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
              <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
              <Dropdown.Item className="d-flex fw-bold" onClick={()=>{setPageSize(5);setCurrentPage(1)}}>5
              {pageSize === 5 && <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>}
              </Dropdown.Item>
              <Dropdown.Item className="d-flex fw-bold" onClick={()=>{setPageSize(10);setCurrentPage(1)}}>10
              {pageSize === 10 && <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>}
              </Dropdown.Item>
              <Dropdown.Item className="fw-bold" onClick={()=>{setPageSize(20);setCurrentPage(1)}}>20
              {pageSize === 20 && <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>}
              </Dropdown.Item>
              <Dropdown.Item className="fw-bold" onClick={()=>{setPageSize(30);setCurrentPage(1)}}>30
              {pageSize === 30 && <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>}
              </Dropdown.Item>
              <Dropdown.Item className="fw-bold" onClick={()=>{setPageSize(filteredInventions.length);setCurrentPage(1)}}>All
              {pageSize === filteredInventions.length && <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>

<div className="d-block d-md-none">
<div style={{cursor:'pointer',marginTop:'10px',marginBottom:'10px'}}>
<figure>
<figcaption className="figure-caption figure-caption-mobile">
  Disclosure Status Timeline
</figcaption>
  <img  className="legendImgThumb" onClick={() => {setShowLegend2Mobile(true)}} src={disclosureTimeline} alt="Disclosure Status Timeline Legend"/>
  </figure>
</div>
</div>

  {/*INVENTIONS TABLE*/}
  <Card border="light" className="table-wrapper table-responsive shadow-sm d-none d-md-block">
    <Card.Body className="pt-0">
      <Table hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Track Code</th>
            <th className="border-bottom">Title</th>
            <th className="border-bottom" style={{cursor:'pointer'}} onClick={() => sortDesc === true ? manualSort('startDate', false) : manualSort('startDate', true) }>Start Date
            <span className="p-1"><FontAwesomeIcon icon={faSort} className="icon-dark" /></span>
            </th>
            <th className="border-bottom" style={{cursor:'pointer'}} onClick={() => sortDesc === true ? manualSort('statusChangeDate', false) : manualSort('statusChangeDate', true) }>Status Change Date
            <span className="p-1"><FontAwesomeIcon icon={faSort} className="icon-dark" /></span>
            </th>
            <th className="border-bottom" >Disclosure Status
            </th>
            <th className="border-bottom">Intellectual Property</th>
            <th className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
          {currentTableData.map(invention => <TableRow key={`invention-${invention["InventionId"]}`} {...invention} />)}
        </tbody>
      </Table>
      {loading && <Skeleton count={10} height={55}/>}
      {currentTableData.length < 1 && inventions.length > 0 && !loading &&
        <section className="d-flex align-items-center mb-1 mt-3">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mt-md-0">
                  </div>
                  <div className="text-center">
                  No results.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>}
        {currentTableData.length < 1 && inventions.length < 1 && !loading &&
          <section className="d-flex align-items-center mb-1 mt-3">
            <Container>
              <Row className="justify-content-center form-bg-image">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mt-md-0">
                    </div>
                    <div className="text-center">
                    There are no invention disclosures or patent applications on file in your name at the UMass Chan Medical School.
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>}


      {filteredInventions.length > 0 && <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

        <Nav>

        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filteredInventions.length}
          pageSize={pageSize}
          onPageChange={page => setCurrentPage(page)}
          />

        </Nav>
        {filteredInventions.length > 0 && <small className="fw-bold">
          Showing <b>{pageSize}</b> out of <b>{filteredInventions.length}</b> entries
        </small>}
      </Card.Footer>}
    </Card.Body>


    {/* IP MODAL */}
    <React.Fragment>
    {currentIP.length > 0 &&
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
          <Modal.Header>
          <Modal.Title className="h3">{currentIP[0].Title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
          <div className="mb-2"><strong>Inventors</strong>: {currentIPInventors.filter(function(i) { return i["FullName"] }).map((inv, index) =><small key={`inventors-${inv["PersonId"]}`}> {inv["FullName"]}{index < currentIPInventors.filter(function(i) { return i["FullName"] }).length - 1 ? ", " : ""}</small>)}</div>
          {currentIP.map(ip => <IPItem {...ip} key={`ipItem-${ip["ipId"]}`}/> )}

          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          </Modal.Footer>
        </Modal>}
    </React.Fragment>

    {/* LEGEND2 MODAL  */}
    <React.Fragment>
      <Modal dialogClassName="legend2" as={Modal.Dialog} centered show={showLegend2} onHide={handleCloseLegend2}>
          <Modal.Header>
          <Modal.Title className="h3">Disclosure Status Timeline</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseLegend2} />
          </Modal.Header>
          <Modal.Body>
          <div className="legend2img">
          <img className="d-none d-md-block" src={disclosureTimeline} alt="Disclosure Status Timeline Legend"/>
          </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleCloseLegend2}>
            Close
          </Button>
          </Modal.Footer>
        </Modal>
    </React.Fragment>

    {/* LEGEND2 MODAL MOBILE  */}
    <React.Fragment>
      <Modal dialogClassName="legendMobile" as={Modal.Dialog} centered show={showLegend2Mobile} onHide={handleCloseLegend2Mobile}>
          <Modal.Header>
          <Modal.Title className="h3">Disclosure Status Timeline</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseLegend2Mobile} />
          </Modal.Header>
          <Modal.Body>
          <div className="legend2img">
          <img className="d-block d-md-none" src={disclosureTimelineMobile} alt="Disclosure Status Timeline Mobile Legend"/>
          </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleCloseLegend2Mobile}>
            Close
          </Button>
          </Modal.Footer>
        </Modal>
    </React.Fragment>
  </Card>

{/*INVENTIONS LIST MOBILE VIEW*/}
  <div className="list-group d-block d-md-none">
  {filteredInventions.map(invention => <MobileList key={`invention-list-mobile-${invention["InventionId"]}`} {...invention} />)}
  {loading && <Skeleton count={10} height={140}/>}
  {filteredInventions.length < 1 && inventions.length > 0 && !loading &&
    <section className="d-flex align-items-center mb-4">
      <Container>
        <Row className="justify-content-center form-bg-image">
          <Col xs={12} className="d-flex align-items-center justify-content-center">
            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mt-md-0">
              </div>
              <div className="text-center">
              No results.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>}
    {filteredInventions.length < 1 && inventions.length < 1 && !loading &&
      <section className="d-flex align-items-center mb-4">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mt-md-0">
                </div>
                <div className="text-center">
                There are no invention disclosures or patent applications on file in your name at the UMass Chan Medical School.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>}

  </div>

  </div>

  );
};

InventorTable.propTypes = {
  userEmail: PropTypes.string,
  id: PropTypes.number,
  TrackCode: PropTypes.string,
  Title: PropTypes.string,
  StatusChangeDate: PropTypes.string,
  Status: PropTypes.string,
  IPStatus: PropTypes.string,
  IP: PropTypes.object,
  IPInventors: PropTypes.array,
  StartDate: PropTypes.string,
  FirstLicOfficer: PropTypes.string,
  Country: PropTypes.string,
  FilingDate: PropTypes.string,
  AppProvNumber: PropTypes.number,
  IPNumber: PropTypes.number,
  Type: PropTypes.string,
}

export default InventorTable;

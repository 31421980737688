import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class EnvIndicator extends Component {

    constructor(props) {
        super(props);

        let envState = {};
        if (window.location.href.indexOf('localhost') !== -1) {
            envState = {
                envDisplay: 'Local Debug Environment',
                envHeaderClass: 'envIndicatorHeaderInnerLocal',
                envFooterClass: 'envIndicatorFooterLocal'
            };
        }
        else if (window.location.href.indexOf('bdi-inventor-dashboard-poc') !== -1) {
            envState = {
                envDisplay: 'Sandbox Environment',
                envHeaderClass: 'envIndicatorHeaderInnerSandBox',
                envFooterClass: 'envIndicatorFooterSandBox'
            };
        }
        else if (window.location.href.indexOf('bdi-inventor-dashboard-development') !== -1) {
            envState = {
                envDisplay: 'Sandbox Environment',
                envHeaderClass: 'envIndicatorHeaderInnerSandBox',
                envFooterClass: 'envIndicatorFooterSandBox'
            };
        }
        else if (window.location.href.indexOf('bridge.engsand') !== -1) {
            envState = {
                envDisplay: 'Sandbox Environment',
                envHeaderClass: 'envIndicatorHeaderInnerSandBox',
                envFooterClass: 'envIndicatorFooterSandBox'
            };
        }
        else if (window.location.href.indexOf('bridge.nonprod') !== -1) {
            envState = {
                envDisplay: 'NonProd Environment',
                envHeaderClass: 'envIndicatorHeaderInnerNonProd',
                envFooterClass: 'envIndicatorFooterNonProd'
            };
        }

        this.state = envState;
    }

    render() {

        /* Render either top or bottom indicators or if production a fragment (nothing) */
        let renderThis = <Fragment></Fragment>;
        if (this.state.envDisplay) {
            if (this.props.position === "top") {
                renderThis = <span>
                <div className="envIndicatorHeaderOuter">
                <div className={this.state.envHeaderClass} href="#">{ this.state.envDisplay }<div className="envIndicatorHeaderSmall">Do not enter production data on this site.</div></div>
                </div>
                </span>;
            }
            else if (this.props.position === "bottom") {
                renderThis = <span className={ this.state.envFooterClass}>{ this.state.envDisplay } - Do not enter production data on this site.</span>
            } else {
              renderThis = <div className={ this.state.envFooterClass}>{ this.state.envDisplay }</div>
            }
        }
        return (
            <span>
            { renderThis }
            </span>
        );
    }
}

EnvIndicator.propTypes = {
    position: PropTypes.string
}

export default EnvIndicator;

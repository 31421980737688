
/* User Interface Config:  This file maps the domain the site runs under to the api url the site will use. */
const clientSettings = {

    // /* AWS Prod */
    "bridge.umassmedcloud.org" : {
        loginUrl: 'https://bridge.umassmedcloud.org/',
        apiGateway: {
            NAME: "bdi-inventor-dashboard-api-gateway-production",
            REGION: "us-east-1",
            URL: "https://23k9qb6d81.execute-api.us-east-1.amazonaws.com/"
        },
        cognito: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_BOKrDfXbm",
            APP_CLIENT_ID: "5hl0b0anbio7qun6e0h35e1s7j",
            IDENTITY_POOL_ID: "us-east-1:86a29da7-82bb-421b-83aa-2625ba416b9f",
            IDENTITY_PROVIDER: "bdi-production-idt-provider",
            DOMAIN: "bdi-production.auth.us-east-1.amazoncognito.com"
        },
        sessionTimeOutMins: 60
        },
    /* AWS NON-PROD */
    "bridge.nonprod.umassmedcloud.org" : {
        loginUrl: 'https://bridge.nonprod.umassmedcloud.org/',
        apiGateway: {
            NAME: "bdi-inventor-dashboard-api-gateway-nonprod",
            REGION: "us-east-1",
            URL: "https://2r8ouwo4pb.execute-api.us-east-1.amazonaws.com/"
        },
        cognito: {
            REGION: "us-east-1",
            USER_POOL_ID: "us-east-1_rsdDfAu02",
            APP_CLIENT_ID: "70msjb89losf7b6ht9jjmr564b",
            IDENTITY_POOL_ID: "us-east-1:9c7d726e-f5b6-48b2-8c83-5c01477ace65",
            IDENTITY_PROVIDER: "bdi-nonprod-idt-provider",
            DOMAIN: "bdi-nonprod.auth.us-east-1.amazoncognito.com"
        },
        sessionTimeOutMins: 60,
        impersonateEnabled: true
        },
    /* AWS DEVELOPMENT */
    "bridge.engsand.umassmedcloud.org" : {
        loginUrl: 'https://bridge.engsand.umassmedcloud.org/',
        apiGateway: {
            NAME: "bdi-inventor-dashboard-api-gateway-development",
            REGION: "us-east-1",
            URL: 'https://nemostfsa4.execute-api.us-east-1.amazonaws.com/',
        },
        cognito: {
          REGION: "us-east-1",
          USER_POOL_ID: "us-east-1_5gPFOclMY",
          APP_CLIENT_ID: "5p2i9mi1pbb2uh1jpgapmq76gc",
          IDENTITY_POOL_ID: "us-east-1:c7153c69-c12a-48b3-9624-d1b9f9b92149",
          IDENTITY_PROVIDER: "bdi-development-idt-provider",
          DOMAIN: "bdi-development.auth.us-east-1.amazoncognito.com"
        },
        sessionTimeOutMins: 60,
        impersonateEnabled: true
        },
        /* LOCAL */
        'localhost' : {
            loginUrl: 'http://localhost:3000/',
            apiGateway: {
                NAME: "bdi-inventor-dashboard-api-gateway-development",
                URL: 'https://nemostfsa4.execute-api.us-east-1.amazonaws.com/'
            },
            cognito: {
              REGION: "us-east-1",
              USER_POOL_ID: "us-east-1_5gPFOclMY",
              APP_CLIENT_ID: "5p2i9mi1pbb2uh1jpgapmq76gc",
              IDENTITY_POOL_ID: "us-east-1:c7153c69-c12a-48b3-9624-d1b9f9b92149",
              IDENTITY_PROVIDER: "bdi-development-idt-provider",
              DOMAIN: "bdi-development.auth.us-east-1.amazoncognito.com"
            },
            sessionTimeOutMins: 60,
            impersonateEnabled: true
    }
  };

  function getApiName(env) {
    var currentSetting = env || 'localhost';
    return clientSettings[currentSetting].apiGateway.NAME;
  }

  function getSessionTimeOutMins(env) {
    var currentSetting = env || 'localhost';
    return clientSettings[currentSetting].sessionTimeOutMins;
  }

  function getConfig(env) {
    var currentSetting = env || 'localhost';
    return clientSettings[currentSetting];
}

export default { getApiName, getSessionTimeOutMins, getConfig};


import React, { useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Nav, Image, Navbar, Dropdown, Container, ListGroup, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import EnvIndicator from './EnvIndicator';
import { Cache } from 'aws-amplify';
import PropTypes from 'prop-types';

import NOTIFICATIONS_DATA from "../../data/notifications";
// import Profile3 from "../../assets/img/team/kontos-square.png";
import Profile3 from "../../assets/img/team/person.png";
import umassMedChanLogo from "../../assets/img/UMassChan_Vertical_2Color.svg";

const NavBar = (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const handleLogout = async () => {
      props.childProps.signOut();
    }

  const Notification = (props) => {
    const { link, sender, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="ps-1 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="p-3 fixed-top">
    <div className="headerLogo"><img src={umassMedChanLogo} alt="UMass Chan Medical School"/></div>


      <Container fluid className="px-3">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
          <Row className="justify-content-md-center mt-3 titleBlock">
        <h4 className="">BRIDGE INVENTOR DASHBOARD
        {false && <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0 ">
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      Inventions
                    </Dropdown.Item>
                    <Dropdown.Item>
                    Intellectual Property
                    </Dropdown.Item>
                    <Dropdown.Item >
                      Agreements
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
      </h4>
      {true && <span className="d-block d-sm-block d-md-none"><EnvIndicator></EnvIndicator></span>}
          </Row>
          </div>
          <Nav className="align-items-center">
    {true && <span className="d-none d-md-block"><EnvIndicator position="top"></EnvIndicator></span>}

        {false && <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
  <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-1 mt-2">
    <span className="icon icon-sm">
      <FontAwesomeIcon icon={faBell} className="bell-shake" />
      {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
    </span>
  </Dropdown.Toggle>
  <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
    <ListGroup className="list-group-flush">
      <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
        Notifications
      </Nav.Link>

      {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

      <Dropdown.Item className="text-center text-primary fw-bold py-3">
        View all
      </Dropdown.Item>
    </ListGroup>
  </Dropdown.Menu>
</Dropdown> }

                    {props.childProps.isAuthenticated
                          ? <Fragment>
                          <Dropdown as={Nav.Item}>
                            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                              <div className="media d-flex align-items-center">
                                <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                                <div className="media-body ms-2 text-dark align-items-center d-none d-sm-block">
                          <span className="mb-0 font-small fw-bold" href="#"> { Cache.getItem("name") } </span>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                        <Dropdown.Item className="fw-bold" onClick={()=> window.location="mailto:innovation@umassmed.edu"}>
                          <FontAwesomeIcon icon={faUserShield} className="me-2"/> Support
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                          <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    </Fragment> : <Fragment></Fragment>}
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

NavBar.propTypes = {
  childProps: PropTypes.any,
  link: PropTypes.string,
  sender: PropTypes.string,
  time: PropTypes.string,
  message: PropTypes.string,
  read: PropTypes.bool
}

export default NavBar;

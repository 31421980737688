import React from "react";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import PropTypes from 'prop-types';

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
  { props.iconlocation === 'left' && props.children}
    {!isLoading ? text : loadingText}
    {isLoading &&
<FontAwesomeIcon icon={faSpinner} className="spinner"/>}
  { props.iconlocation === 'right' && props.children}
  </Button>;

LoaderButton.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconlocation: PropTypes.string,
  children: PropTypes.any,
}

export default LoaderButton;

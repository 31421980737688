// import React, { Component } from 'react';
import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from '@themesberg/react-bootstrap';
import { Auth, Cache, Hub } from 'aws-amplify';
import  LoaderButton from './LoaderButton';
import Config from "../Config";
import PropTypes from 'prop-types';

function Login(props) {
  // const [name, setName] = useState('');
  // const [loginMessage, setLoginMessage] = useState('');
  // const [loginSuccess, setLoginSuccess] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isOffice365Loading, setIsOffice265Loading] = useState(false);
  // const [error, setError] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(props.isAuthenticated);
  const [isAuthenticating, setIsAuthenticating] = useState(window.location.search.includes("code"));

  useEffect(() => {
    if (!isAuthenticating) {
      getCognitoUser();
    }

      //Listens for Cognito signin events
      Hub.listen("auth", ({ payload: { event } }) => {
        switch (event) {
          case "signIn":
            Cache.setItem("isOffice365Authenticating", false);
            getCognitoUser();
            break;
          case 'signIn_failure':
            setIsAuthenticating(false);
            // setIsLoading(false);
            // setLoginMessage(data.message);
            break;
          default:
            break;
        }
      });

      return () => {
          // Anything in here is fired on component unmount.
          // cleanup
          Hub.remove("auth");
      }

  }, [])

  const setUser = (name, email) => {
      // setTimeout(function() {
      //   // Cache.setItem("email", email.toLowerCase());
      //   props.setEmail(email.toLowerCase());
      // }, 3000);
      props.setEmail(email.toLowerCase());
      Cache.setItem("name", name);
      props.userHasAuthenticated(true);

      //Redirect to dashboard OR first step of wizard if coming from email
      let deepLink = Cache.getItem("deepLink");
      if (deepLink) {
        if (deepLink === ('/')) {
          props.history.push({
            pathname: '/'
          })
        } else {
          props.history.push({
            pathname: deepLink
            })
        }
      }
       else {
        props.history.push({
          pathname: '/',
              })
      }
    }

    const getCognitoUser = () => {
        Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
      .then(user => {
        setUser(user.signInUserSession.idToken.payload.given_name, user.signInUserSession.idToken.payload.email);
        setIsAuthenticating(false);
        // setName(user.signInUserSession.idToken.payload.given_name && user.signInUserSession.idToken.payload.family_name ? `${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}` : null)
      })
      .catch(() => props.userHasAuthenticated(false));
    }

  /* Performs login with identity provider connected to Cognito User Pool  (Azure AD) */
  const office365Login = async () => {
    var currentHost = window.location.hostname || 'localhost';
    var config = Config.getConfig(currentHost);
    Cache.setItem("isOffice365Authenticating", true);
    setIsOffice265Loading(true);
    await Auth.federatedSignIn({provider: config.cognito.IDENTITY_PROVIDER});
    setIsOffice265Loading(false)
    return;
  }

    var currentHost = window.location.hostname || 'localhost';
    var config = Config.getConfig(currentHost);

    return (
      <section className="d-flex align-items-center mb-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">


              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mt-md-0">
                </div>

                <div className="text-center">
                    {isAuthenticating &&
                        <span>
                          Please wait while your credentials are verified...
                          </span>
                    }
                    {props.isAuthenticated && !props.userEmail &&
                        <span>
                          Getting user information...
                          </span>
                    }
                    {!isAuthenticating &&
                      <div>
                        {window.location.hostname !== "bdi-search.umassmedcloud.org" && <span></span>}
                        {config.cognito.IDENTITY_PROVIDER !== undefined &&
                        <span>
                            {!props.isAuthenticated &&
                          <LoaderButton size="lg" color="primary" type="button" text="Login with Office365"
                                    onClick={office365Login}
                                    isLoading={isOffice365Loading}
                                    loadingText="Logging in…" />
                                    }
                        </span>
                        }
                      </div>
                      }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    )
  }

Login.propTypes = {
  setEmail: PropTypes.func,
  userHasAuthenticated: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isAuthenticated: PropTypes.bool,
  userEmail: PropTypes.string,
}
  
export default Login;

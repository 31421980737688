import { API } from 'aws-amplify';
import Config from "../../../Config"

var init = {
    response: true, //return entire response object instead of response.data
    queryStringParameters: {},
    body: {}
  }

export class InventorAPI
{
    constructor() {
        var currentHost = window.location.hostname || 'localhost';
        this.apiName = Config.getApiName(currentHost)
    }

    async get(path, params) {
        init.queryStringParameters = params;
        return API.get(this.apiName, path, init);
    }
}

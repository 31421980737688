import React, { Fragment, Component } from 'react';
import { Auth, Cache } from 'aws-amplify';
import Main from './components/layout/Main';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import IdleTimer from 'react-idle-timer'
import Config from "./Config";
class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      userEmail: null
    };
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    var currentHost = window.location.hostname || 'localhost';
    this.timeOutMins = Config.getSessionTimeOutMins(currentHost)
}

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.setState({ isAuthenticated: true });
        Auth.currentSession().then(()=>{
          // let accessToken = res.getAccessToken()
          // let jwt = accessToken.getJwtToken()
          //You can print them to see the full objects
          //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
          //console.log(`myJwt: ${jwt}`)
        })
      }
      else {
        this.setState({ isAuthenticated: false });
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        // alert(JSON.stringify(e));
        // console.log(JSON.stringify(e))
      }
    }
     this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setEmail = email => {
    // alert(email)
    this.setState({ userEmail: email.toLowerCase() });
  }

  async signOut() {
    try {
      await Auth.signOut();
      Cache.clear();
      this.setState({ isAuthenticated: false });
    }
    catch(e) {
      console.log(e)
    }
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setEmail: this.setEmail,
      userEmail: this.state.userEmail,
      signOut: this.signOut
    }



    return (
      <div className="App">
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * this.timeOutMins}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction} debounce={250}  />
          <Fragment>
            <div className="wrapper">
              <Navbar childProps={childProps}/>
              <Main childProps={childProps}/>
              <Footer />
            </div>
          </Fragment>
      </div>
    );
  }

  handleOnIdle () {
    this.signOut();
  }

}

export default App;

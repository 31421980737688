import React from 'react';
import {BrowserRouter as Router,Switch} from 'react-router-dom';
import InventorDashboard from "../../pages/InventorDashboard";
import PropTypes from 'prop-types';

import AppliedRoute from '../common/routes/AppliedRoute';

const Main = ({ childProps }) =>
  <main className="content mt-7">
    <Router>
      <Switch>
        <AppliedRoute path="/" exact component={InventorDashboard} props={childProps} />
      </Switch>
    </Router>
  </main>

Main.propTypes = {
  childProps: PropTypes.any
}

export default Main;


import React  from "react";
// import Login from "../components/Login";
import Login from "../components/Login";
import InventorTable from "./components/inventor-dashboard/InventorTable";
import PropTypes from 'prop-types';

const InventorDashboard = (props) => {
  return (
    <>
    {props.isAuthenticated && props.userEmail ? <div>
    <InventorTable {...props} />
    </div> : <Login {...props}/>}
    </>
  );
};

InventorDashboard.propTypes = {
  isAuthenticated: PropTypes.bool,
  userEmail: PropTypes.string,
}

export default InventorDashboard;
